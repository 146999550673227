.signup-info-container {
    width: 100%;
    height: 100vh;
    padding: 30px 0px;
    margin: 0px;
    background-color: white;
    overflow: auto;
}

.links {
    text-decoration: none;
}


.r {
    color: #85C7DD;
}

.w {
    color: white;
}

.no-dec {
    text-decoration: none;
}

.signup-logo {
    margin: 0px auto;
    width: 100px;
}

.signup-form-error-message {
    color: red;
    font-family: "Poppins", "cursive";
    text-align: center;
}

.signup-message {
    color: black;
    margin: 0px auto;
    margin-top: -20px;
    text-align: center;
}

.signup-confirmation-footer-follow-soon-icons-container {
    margin: 25px auto;
    width: 100%;
    text-align: center;
}

.signup-confirmation-footer-follow-soon-container {
    text-align: center;
}

.signup-confirmation-footer-follow-soon-icons-container {
    display: block;
}

.signup-confirmation-footer-follow-soon-icons-title {
    margin: 0px auto;
}

.signup-confirmation-footer-follow-soon-icons-title:hover {
    cursor: default;
}

.signup-confirmation-footer-follow-soon-icons-title {
    margin-top: 75px;
    font-size: larger;
}

.signup-confirmation-footer-follow-soon-icons {
    width: 100%;
    margin-top: 10px;
}

.signup-confirmation-footer-follow-soon-icon {
    margin: 0px 10px;
}

.signup-confirmation-footer-follow-social-icon {
    height: 35px;
}
/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {

    .signup-info-box {
        color: #85C7DD;
        width: 80%;
        margin: 25px auto;
        margin-bottom: 50px;
        padding: 25px;
        border-radius: 10px;
    }

    .signup-info-title {
        font-size: 35px;
        margin: 0px;
        text-align: center;
    }

    .signup-info-box-quote {
        font-family: "Poppins", "cursive";
    }

    .signup-info-box button {
        display: block;
        width: 85%;
        margin: 15px auto;   
    }

    .signup-info-box-apple {
        background-color: white;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        height: 35px;
    }

    .signup-info-spacer {
        margin: 50px auto;
        font-family: "Poppins", "cursive";
    }

    .form-input {
        display: block;
        margin: 15px auto;
        width: 85%;
        height: 30px;
        border: solid 1px #85C7DD;
        border-radius: 5px;
        background-color: white;
        text-align: center;
        font-size: 15px;
        text-align-last: center;
    }

    .form-button {
        background-color: #85C7DD;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 85%;
        font-family: "Poppins", "cursive";
    }

    .form-button {
        cursor: pointer;
    }

    .form-button:hover {
        background-color: #2cc8fb;
    }

    .signup-info-box-forgot-password {
        font-family: "Poppins", "cursive";
        margin: 25px;
        color: #85C7DD
    }

    .signup-info-box-signup-info {
        font-family: "Poppins", "cursive";
    }

    .signup-info-box-signup-info strong {
        color: #85C7DD;
    }

    .signup-info-footer {
        font-family: "Poppins", "cursive";
        font-size: 10px;
        margin: 0 auto;
        margin-top: 250px;
        padding-bottom: 50px;
        text-align: center;
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .signup-info-title {
        margin: 0px auto;
        width: 100%;
        font-size: 35px;
        color: #85C7DD; 
        text-align: center;
    }

    .signup-info-box {
        background-color: white;
        width: 575px;
        margin: 25px auto;
        margin-bottom: 50px;
        padding: 25px;
        border-radius: 10px;
    }

    .signup-info-box-title {
        font-family: "Indie Flower", "cursive";
        font-size: 35px;
        margin: 20px auto;
        text-align: center;
    }

    .signup-info-box-quote {
        font-family: "Poppins", "cursive";
    }

    .signup-info-box button {
        display: block;
        width: 85%;
        margin: 15px auto;   
    }

    .form-input {
        display: block;
        margin: 15px auto;
        width: 85%;
        height: 30px;
        border: solid 1px #85C7DD;
        border-radius: 5px;
        background-color: white;
        text-align: center;
        font-size: 15px;
    }

    .form-button {
        background-color: #85C7DD;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 85%;
        font-family: "Poppins", "cursive";
    }


    .form-button:hover {
        background-color: #2cc8fb;
    }

    .signup-info-box-forgot-password {
        font-family: "Poppins", "cursive";
        margin: 25px;
        color: #85C7DD
    }

    .signup-info-box-signup-info {
        font-family: "Poppins", "cursive";
    }

    .signup-info-box-signup-info strong {
        color: #85C7DD;
    }

    .signup-info-footer {
        margin-top: 250px;
        text-align: center;
    }

}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

/* @media only screen and (min-width: 992px) {} */