.container {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
}

.links {
    text-decoration: none;
}


.r {
    color: #FF2F46;
}

.w {
    color: white;
}

.welcomecs-footer-follow-soon-icon {
    opacity: .7;
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {

    .mobile-hide {
        display: none;
    }

    .welcomecs-footer-become-member-container {
        display: none;
    }

    .header {
        width: 100%;
        padding: 10px 0;
        display: flex;
        flex-direction: row;
    }

    .header-title {
        margin: auto 10px;
        width: 50%;
        font-size: 13px;
    }

    .header-logo {
        width: 120px;
        margin-top: 5px;
    }
    
    .home-header-title:hover {cursor: default;}

    .header-items {
        display: flex;
        padding: 0;
        margin: 5px 10px;
        list-style-type: none;
        width: 50%;
        justify-content: flex-end;
    }

    .header-item {
        margin: auto 0px;
        font-size: 12px;
        color: black;
    }

    .header-item:hover {
        border-bottom: solid #ED9594 2px;
    }

    .welcomecs-intro-content-container {
        color: white;
        width: 100%;
        text-align: center;
        cursor: default;
        margin-top: 15px;
    }

    .welcomecs-intro-content-container h1 {
        margin-bottom: 0px;
    }

    .welcomecs-intro-content-container h2 {
        margin-top: 0px;
    }

    .welcomecs-countdown-style {
        display: inline-block;
        vertical-align: top;
        margin: 0px 10px;
        color: #85C7DD;
    }

    .welcomecs-email-launch-notification-form-container {
        color: white;
        width: 100%;
        text-align: center;
        margin-top: 50px;
    }   

    .welcomecs-email-launch-notification-form-container-input {
        display: block;
        margin: 0px auto;
        width: 280px;
        height: 30px;
        background-color: transparent;
        border: 1px gray solid;
        border-radius: 10px;
        color: white;
        padding: 0px 10px;
        text-align: center;
        border: 1px solid #ED9594;
    }

    .welcomecs-email-launch-notification-form-container button {
        display: block;
        margin: 10px auto;
        width: 300px;
        height: 30px;
        background-color: transparent;
        border: none;
        color: white;
        border: 1px solid #85C7DD;
        border-radius: 10px;
    }

    .welcomecs-email-launch-notification-form-container button:hover {
        background-color: #2cc8fb;
        cursor: pointer;
    }

    .main-image-container {
        background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url(../images/images/gwen-king-3OdajQGd9sk-unsplash.jpg);
        background-repeat: no-repeat;
        background-size:cover;
        background-position: center;
        min-height: 100vh;
        padding: 20px;
        margin: 0px;
        display: block;
    }

    .upcoming-event-section-button {
        width: 80%;
        height: 40px;
        border-radius: 10px;
        margin: 20px auto;
        border: none;
        background-color: #85C7DD;
        color: white;
        font-size: 15px;
    }

    .upcoming-event-section-button:hover {
        cursor: pointer;
        background-color: #2cc8fb;
    }

    .welcomecs-footer-container {
        color: black;
        width: 100%;
        padding: 20px 0px;
    }

    .welcomecs-footer-list-container {
        width: 100%;
        margin-top: 50px;
        text-align: center;
    }

    .welcomecs-footer-list-item {
        list-style-type: none;
        padding: 0px;
        margin-top: 10px;
        font-weight: lighter;
        font-size: 15px;
    }

    .welcomecs-footer-list-item:hover {
        color: #ED9594;
    }

    .welcomecs-footer-list-item-question:hover {
        cursor: default;
        color: white;
    }

    .welcomecs-footer-follow-soon-container {
        text-align: center;
    }

    .welcomecs-footer-follow-soon-icons-container {
        display: block;
    }

    .welcomecs-footer-follow-soon-icons-title {
        margin: 0px auto;
    }

    .welcomecs-footer-follow-soon-icons-title:hover {
        cursor: default;
    }

    .welcomecs-footer-follow-soon-icons-title {
        margin-top: 75px;
        color: white;
        font-size: larger;
    }

    .welcomecs-footer-follow-soon-icons {
        width: 100%;
        margin-top: 10px;
    }

    .welcomecs-footer-follow-soon-icon {
        margin: 0px 10px;
    }

    .welcomecs-footer-follow-social-icon {
        height: 35px;
    }

    .welcomecs-footer-follow-soon-store-container {
        display: inline-block;
        vertical-align: top;
        margin: auto 50px;
    }

    .welcomecs-footer-follow-soon-icons-google {
        width: 135px;
    }

    .welcomecs-footer-follow-soon-icons-apple {
        width: 135px;
    }

    .welcomecs-footer-bottom-title {
        font-size: 10px;
        margin-top: 50px;
        width: 100%;
        text-align: center;
    }

    .welcomecs-footer-bottom-title-title {
        margin: 0px 10px;
        color: white;
    }

    .welcomecs-footer-bottom-title-title:hover {
        cursor: default;
    }

    .welcomecs-footer-bottom-title-link {
        color: white;
        margin: 0px 10px;
    }

    .welcomecs-footer-bottom-title-link:hover {
        color:#ED9594;
    }

    .welcomecs-footer-blogs-title {
        margin: 0px;
        margin-top: 75px;
        width: 100%;
        text-align: center;
        color: white;
        font-size: larger;
    }

    .welcomecs-footer-blogs-container {
        width: 100%;
        height: 350px;
        display: block;
        margin: 0px;
        margin-top: 25px;
        white-space: nowrap;
        overflow-x: scroll; 
        overflow-y: hidden; 
    }

    ::-webkit-scrollbar {
        width: 0px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #525252;
        opacity: .5;
    }

    .welcomecs-blog-box {
        display: inline-block;
        vertical-align: top;
        width: 305px;
        height: 300px;
        border: solid 2px #ED9594;
        border-radius: 5px;
        background-color: #232323;
        margin: 10px;
        overflow: hidden;
    }

    .welcomecs-blog-image {
        max-height: 70%;
        min-width: 100%;
    }

    .welcomecs-blog-title {
        width: 90%;
        color: white;
        overflow-wrap: break-word;
        margin: 0px 5px;
        height: 50px;
    }
}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .hide-tablet {
        display: none;
    }

    .welcomcs-special-message {
        width: 50%;
    }

    .header {
        width: 100%;
        padding: 10px 0;
        display: flex;
        flex-direction: row;
    }

    .header-title {
        margin: auto 10px;
        width: 50%;
        font-size: 13px;
    }

    .header-logo {
        width: 120px;
        margin-top: 5px;
    }
    
    .home-header-title:hover {cursor: default;}

    .header-items {
        display: flex;
        padding: 0;
        margin: 5px 10px;
        list-style-type: none;
        width: 50%;
        justify-content: flex-end;
    }

    .header-item {
        margin: auto 0px;
        font-size: 12px;
        color: black;
    }

    .header-item:hover {
        border-bottom: solid #ED9594 2px;
    }

    .welcomecs-intro-content-container {
        color: white;
        width: 500px;
        text-align: center;
        cursor: default;
        margin: 0px auto;
        margin-top: 15px;
    }

    .welcomecs-intro-content-container h1 {
        margin-bottom: 0px;
    }

    .welcomecs-intro-content-container h2 {
        margin-top: 0px;
    }

    .welcomecs-countdown-style {
        display: inline-block;
        vertical-align: top;
        margin: 0px 10px;
        color: #85C7DD;
    }

    .welcomecs-email-launch-notification-form-container {
        color: white;
        width: 100%;
        text-align: center;
        margin-top: 50px;
    }   

    .welcomecs-email-launch-notification-form-container-input {
        display: block;
        margin: 0px auto;
        width: 300px;
        height: 30px;
        background-color: transparent;
        border: 1px gray solid;
        border-radius: 10px;
        color: white;
        padding: 0px 10px;
        text-align: center;
    }

    .welcomecs-email-launch-notification-form-container button {
        display: block;
        margin: 10px auto;
        width: 320px;
        height: 30px;
        background-color: transparent;
        border: none;
        color: white;
        border: 1px solid #ED9594;
        border-radius: 10px;
    }

    .welcomecs-email-launch-notification-form-container button:hover {
        background-color: #ED9594;
        cursor: pointer;
    }

    .main-image-container {
        background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url(../images/images/andre-benz-Mn9Fa_wQH-M-unsplash.jpg);
        background-repeat: no-repeat;
        background-size:cover;
        background-position: center;
        min-height: 100vh;
        padding: 20px;
        margin: 0px;
        display: block;
    }

    .upcoming-event-section-button {
        width: 320px;
        height: 40px;
        border-radius: 10px;
        margin: 20px auto;
        margin-top: 55px;
        border: none;
        background-color: #85C7DD;
        color: white;
        font-size: 15px;
    }

    .upcoming-event-section-button:hover {
        cursor: pointer;
        background-color: #2cc8fb;
    }

    .welcomecs-footer-container {
        color: black;
        width: 100%;
        padding: 20px 0px;
    }

    .welcomecs-footer-list-container {
        width: 100%;
        margin-top: 50px;
        text-align: center;
    }

    .welcomecs-footer-list-item {
        list-style-type: none;
        padding: 0px;
        margin-top: 10px;
        font-weight: lighter;
        font-size: 15px;
    }

    .welcomecs-footer-list-item:hover {
        color: #ED9594;
    }

    .welcomecs-footer-list-item-question:hover {
        cursor: default;
        color: white;
    }

    .welcomecs-footer-follow-soon-container {
        text-align: center;
    }

    .welcomecs-footer-follow-soon-icons-container {
        display: block;
    }

    .welcomecs-footer-follow-soon-icons-title {
        margin: 0px auto;
    }

    .welcomecs-footer-follow-soon-icons-title:hover {
        cursor: default;
    }

    .welcomecs-footer-follow-soon-icons-title {
        margin-top: 45px;
        color: white;
        font-size: larger;
    }

    .welcomecs-footer-follow-soon-icons {
        width: 100%;
        margin-top: 10px;
    }

    .welcomecs-footer-follow-soon-icon {
        margin: 0px 10px;
    }

    .welcomecs-footer-follow-social-icon {
        height: 35px;
    }

    .welcomecs-footer-follow-soon-store-container {
        display: inline-block;
        vertical-align: top;
        margin: auto 50px;
    }

    .welcomecs-footer-follow-soon-icons-google {
        width: 135px;
    }

    .welcomecs-footer-follow-soon-icons-apple {
        width: 135px;
    }

    .welcomecs-footer-bottom-title {
        font-size: 10px;
        margin-top: 50px;
        width: 100%;
        text-align: center;
    }

    .welcomecs-footer-bottom-title-title {
        margin: 0px 10px;
        color: white;
    }

    .welcomecs-footer-bottom-title-title:hover {
        cursor: default;
    }

    .welcomecs-footer-bottom-title-link {
        color: white;
        margin: 0px 10px;
    }

    .welcomecs-footer-bottom-title-link:hover {
        color:#ED9594;
    }

    .welcomecs-footer-blogs-title {
        margin: 0px;
        margin-top: 75px;
        width: 100%;
        text-align: center;
        color: white;
        font-size: larger;
    }

    .welcomecs-footer-blogs-container {
        width: 100%;
        height: 350px;
        display: block;
        margin: 0px;
        margin-top: 25px;
        white-space: nowrap;
        overflow-x: scroll; 
        overflow-y: hidden; 
    }

    ::-webkit-scrollbar {
        width: 0px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #525252;
        opacity: .5;
    }

    .welcomecs-blog-box {
        display: inline-block;
        vertical-align: top;
        width: 305px;
        height: 300px;
        border: solid 2px #FF2F46;
        border-radius: 5px;
        background-color: #232323;
        margin: 10px;
        overflow: hidden;
    }

    .welcomecs-blog-image {
        max-height: 70%;
        min-width: 100%;
    }

    .welcomecs-blog-title {
        width: 90%;
        color: white;
        overflow-wrap: break-word;
        margin: 0px 5px;
        height: 50px;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    
    .header {
        width: 100%;
        padding: 10px 0;
        display: flex;
        flex-direction: row;
    }

    .header-title {
        margin: auto 10px;
        width: 50%;
        font-size: 13px;
    }

    .header-logo {
        width: 120px;
        margin-top: 5px;
    }
    
    .home-header-title:hover {cursor: default;}

    .header-items {
        display: flex;
        padding: 0;
        margin: 5px 10px;
        list-style-type: none;
        width: 50%;
        justify-content: flex-end;
    }

    .header-item {
        margin: auto 0px;
        font-size: 12px;
        color: black;
    }

    .header-item:hover {
        border-bottom: solid #ED9594 2px;
    }

    .welcomecs-intro-content-container {
        color: white;
        width: 500px;
        text-align: center;
        cursor: default;
        margin: 0px auto;
        margin-top: 15px;
    }

    .welcomecs-intro-content-container h1 {
        margin-bottom: 0px;
    }

    .welcomecs-intro-content-container h2 {
        margin-top: 0px;
    }

    .welcomecs-countdown-style {
        display: inline-block;
        vertical-align: top;
        margin: 0px 10px;
        color: #85C7DD;
    }

    .welcomecs-email-launch-notification-form-container {
        color: white;
        width: 100%;
        text-align: center;
        margin-top: 50px;
    }   

    .welcomecs-email-launch-notification-form-container-input {
        display: block;
        margin: 0px auto;
        width: 300px;
        height: 30px;
        background-color: transparent;
        border: 1px gray solid;
        border-radius: 10px;
        color: white;
        padding: 0px 10px;
        text-align: center;
    }

    .welcomecs-email-launch-notification-form-container button {
        display: block;
        margin: 10px auto;
        width: 320px;
        height: 30px;
        background-color: transparent;
        border: none;
        color: white;
        border: 1px solid #ED9594;
        border-radius: 10px;
    }

    .welcomecs-email-launch-notification-form-container button:hover {
        background-color: #ED9594;
        cursor: pointer;
    }

    .main-image-container {
        background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url(../images/images/gracia-dharma-qTlbO6mkQH0-unsplash.jpg);
        background-repeat: no-repeat;
        background-size:cover;
        background-position: center;
        min-height: 100vh;
        padding: 20px;
        margin: 0px;
        display: block;
    }

    .upcoming-event-section-button:hover {
        cursor: pointer;
        background-color: #2cc8fb;
    }

    .welcomecs-footer-container {
        color: black;
        width: 100%;
        padding: 20px 0px;
    }

    .welcomecs-footer-list-container {
        width: 100%;
        margin-top: 50px;
        text-align: center;
    }

    .welcomecs-footer-list-item {
        list-style-type: none;
        padding: 0px;
        margin-top: 10px;
        font-weight: lighter;
        font-size: 15px;
    }

    .welcomecs-footer-list-item:hover {
        color: #ED9594;
    }

    .welcomecs-footer-list-item-question:hover {
        cursor: default;
        color: white;
    }

    .welcomecs-footer-follow-soon-container {
        text-align: center;
    }

    .welcomecs-footer-follow-soon-icons-container {
        display: block;
    }

    .welcomecs-footer-follow-soon-icons-title {
        margin: 0px auto;
    }

    .welcomecs-footer-follow-soon-icons-title:hover {
        cursor: default;
    }

    .welcomecs-footer-follow-soon-icons-title {
        margin-top: 45px;
        color: white;
        font-size: larger;

    }

    .welcomecs-footer-follow-soon-icons {
        width: 100%;
        margin-top: 10px;
    }

    .welcomecs-footer-follow-soon-icon {
        margin: 0px 10px;
    }

    .welcomecs-footer-follow-social-icon {
        height: 35px;
    }

    .welcomecs-footer-follow-soon-store-container {
        display: inline-block;
        vertical-align: top;
        margin: auto 50px;
    }

    .welcomecs-footer-follow-soon-icons-google {
        width: 135px;
    }

    .welcomecs-footer-follow-soon-icons-apple {
        width: 135px;
    }

    .welcomecs-footer-bottom-title {
        font-size: 10px;
        margin-top: 50px;
        width: 100%;
        text-align: center;
    }

    .welcomecs-footer-bottom-title-title {
        margin: 0px 10px;
        color: white;
    }

    .welcomecs-footer-bottom-title-title:hover {
        cursor: default;
    }

    .welcomecs-footer-bottom-title-link {
        color: white;
        margin: 0px 10px;
    }

    .welcomecs-footer-bottom-title-link:hover {
        color:#ED9594;
    }

    .welcomecs-footer-blogs-title {
        margin: 0px;
        margin-top: 75px;
        width: 100%;
        text-align: center;
        color: white;
        font-size: larger;
    }

    .welcomecs-footer-blogs-container {
        width: 100%;
        height: 350px;
        display: block;
        margin: 0px;
        margin-top: 25px;
        white-space: nowrap;
        overflow-x: scroll; 
        overflow-y: hidden; 
        text-align: center;
    }

    ::-webkit-scrollbar {
        width: 0px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #525252;
        opacity: .5;
    }

    .welcomecs-blog-box {
        display: inline-block;
        vertical-align: top;
        width: 305px;
        height: 300px;
        border: solid 2px #FF2F46;
        border-radius: 5px;
        background-color: #232323;
        margin: 10px;
        overflow: hidden;
    }

    .welcomecs-blog-image {
        max-height: 70%;
        min-width: 100%;
    }

    .welcomecs-blog-title {
        width: 90%;
        color: white;
        overflow-wrap: break-word;
        margin: 0px 5px;
        height: 50px;
        text-align: start;
    }

}