.signup-confirmation-container {
    width: 100%;
    padding: 30px 0px;
    margin: 0px auto;
    background-color: white;
}

.animates-logo-container {
    margin: 0px;
}

.animates-logo {
    width: 170px;
    margin-top: 0px;
    margin-left: 0px;
}

.confirmation {
    height: 100vh;
}

.links {
    text-decoration: none;
}

.no-dec {
    text-decoration: none;
}

.signup-confirmation-title {
    font-family: "Permanent Marker", "cursive";
}

.signup-confirmation-message {
    font-family: "Poppins", "cursive";
    width: 85%;
    margin: 50px auto;
    margin-top: 50px;
    font-size: 15px;
    text-align: center;
}

.signup-confirmation-continue {
    margin: 10px auto;
    display: block;
    background-color: #85C7DD;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 300px;
}

.signup-confirmation-continue:hover {
    cursor: pointer;
    background-color: #2cc8fb;
}

.signup-confirmation-create-event {
    width: 55px;
    height: 17px;
    color: white;
    background-color: #85C7DD;
    border: none;
    border-radius: 5px;
    margin: 0px 2px;
    padding: auto;
}

.signup-confirmation-create-event:hover {
    cursor: pointer;
}

.signup-confirmation-footer {
    margin-top: 100px;
}
