.signup-container {
    width: 100%;
    padding: 30px 0px;
    margin: 0px;
    background-color: white;
    overflow: auto;
    height: 100vh;
}

.animates-logo-container {
    margin: 0px;
}

.animates-logo {
    width: 170px;
    margin-top: 0px;
    margin-left: 0px;
}

.confirmation {
    height: 100vh;
}

.links {
    text-decoration: none;
}

.no-dec {
    text-decoration: none;
}

.signup-logo-icon {
    width: 100px;
}

.signup-with-google-container {
    width: 320px;
    margin: 0px auto;
    text-align: center;
}

.signup-spacer {
    font-family: "Poppins", "cursive";
    color: white;
    margin-top: 20px;
}

.signup-with-email:hover {
    cursor:pointer;
    background-color: #85C7DD;
}

.sign-up-membership-checkbox {
    width:20px;
    height:20px;
    background:white;
    border-radius:5px;
    border:2px solid #555;
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
}

.checkbox-plan {
    display: inline-block;
    height: 10px;
}



/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {

    .signup-box {
        background-color: white;
        color: black;
        width: 80%;
        margin: 5px auto;
        margin-bottom: 50px;
        padding: 25px;
        border-radius: 10px;
        text-align: center;
        overflow: auto;
        padding-top: 0px;
    }

    .signup-box .signup-box-title {
        font-size: 30px;
        margin: 20px auto;
    }

    .signup-box-apple {
        background-color: white;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        height: 35px;
        width: 350px;
        margin: 10px auto;
    }

    .signup-box-apple:hover {cursor:pointer;}

    .signup-box-email:hover {cursor:pointer;}

    .signup-spacer {
        margin: 50px auto;
        font-family: "Poppins", "cursive";
    }
    
    .signup-box-signup {
        font-family: "Poppins", "cursive";
        width: 95%;
        margin: 0px auto;
        margin-bottom: 150px;
        font-size: 10px;
    }

    .signup-box-signup strong {
        color: #85C7DD;
    } 

    .signup-with-email {
        background-color: #232323;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 45px;
        width: 100%;
        margin: 20px auto;
        text-align: center;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {

    /* .signup-title {
        margin: 0px auto;
        font-family: "Permanent Marker", "cursive";
        width: 100%;
        font-size: 35px;

    } */

    .signup-box {
        width: 570px;
        margin: 0px auto;
        margin-bottom: 50px;
        padding: 25px;
        padding-top: 0px;
        border-radius: 10px;
        text-align: center;
    }

    .signup-box .signup-box-title {
        font-family: "Permanent Marker", "cursive";
        font-size: 35px;
        margin: 20px auto;
    }



    .signup-box-apple:hover {cursor:pointer;}

    .signup-box-email:hover {cursor:pointer;}

    .signup-spacer {
        margin: 50px auto;
        font-family: "Poppins", "cursive";
    }

    .form-button {
        background-color: #FF2F46;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 35px;
        width: 85%;
        font-family: "Poppins", "cursive";
    }

    .form-button {cursor: pointer}



    .signup-box-signup {
        font-family: "Poppins", "cursive";
        width: 320px;
        margin: 25px auto;
        font-size: 10px;
    }

    .signup-box-signup strong {
        color: #85C7DD ;
    }

    .signup-with-email {
        background-color: #232323;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        height: 45px;
        width: 320px;
        margin: 20px auto;
        text-align: center;
    }

    /* .signup-footer {
        font-family: "Poppins", "cursive";
        background-color: #232323;
        margin-top: 0px;
    } */

}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

/* @media only screen and (min-width: 992px) {} */