html {
  font-family: "Poppins", "cursive";
  padding: 0px;
}

body {
  margin: 0px;
}

.color-1 {
  color: #85C7DD;
}

.color-2 {
  color: #ED9594;
}

.color-3 {
  color: #B6D4DC;
}


.error {
  color: red;
}

.b {
  color: black;
}
/* input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
} */