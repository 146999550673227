.privacy-section {
    width: 90%;
    padding: 10px;
    text-align: center;
    font-family: "Poppins", "cursive";
    padding-bottom: 200px;
}

.animates-logo-container {
    margin: 0px;
}

.animates-logo {
    width: 170px;
    margin-top: 0px;
    margin-left: 0px;
}

.privacy-button {
    background-color: #FF2F46;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    height: 35px;
    width: 85%;
    font-family: "Poppins", "cursive";
    margin-top: 30px;
}

.privacy-button {cursor: pointer}

.privacy-event {
    font-family: "Poppins", "cursive";
}

/* **************************************************************************** 

MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE 

**************************************************************************** */

@media only screen and (max-width: 600px) {
    .privacy-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .privacy-title {
        color: #ED9594;
        margin-bottom: 0px;
    }

    .privacy-section {
        text-align: center;
        width: 80%;
        margin: auto;
    }

}

/* **************************************************************************** 

TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET TABLET 

**************************************************************************** */

@media only screen and (min-width: 600px) {
    .privacy-title-container {
        width: 100%;
        text-align: center;
        margin-top: 75px;
    }

    .privacy-title {
        color: #FF2F46;
        margin-bottom: 0px;
    }

    .privacy-section {
        text-align: center;
        width: 70%;
        margin: auto;
    }

    .privacy-button {
        width: 400px;
    }
}

/* **************************************************************************** 

DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP DESKTOP

**************************************************************************** */

@media only screen and (min-width: 992px) {
    .privacy-title {
        color: #FF2F46;
        margin-bottom: 0px;
        margin-top: 75px;
    }

    .privacy-section {
        text-align: center;
        width: 800px;
        margin: auto;
    }

}